import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bp-user-overview" }
const _hoisted_2 = { class: "bp-user-overview__content-wrapper" }
const _hoisted_3 = { class: "bp-user-overview__table-prefix" }
const _hoisted_4 = {
  key: 0,
  class: "bp-user-overview__table-prefix"
}
const _hoisted_5 = { class: "bp-user-overview__table-prefix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_table = _resolveComponent("bp-table")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_window = _resolveComponent("bp-window")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      class: "bp-user-overview__header",
      image: "/static/background/app_background_users.jpg",
      icon: ['fad', 'user-check']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Registered users")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_bp_table, {
        data: _ctx.filterAdmins ? _ctx.store.getAdminCandidates() : _ctx.store.users,
        "disabled-rows": _ctx.disabledRows,
        headers: _ctx.headers,
        loading: _ctx.store.isLoading(),
        "fill-height": ""
      }, {
        prefix: _withCtx(({ selection, count }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_bp_icon, { icon: "check" }),
            _createVNode(_component_translate, {
              "translate-plural": "<strong>%{count}</strong> selected users",
              "translate-n": selection.size,
              "translate-params": { count: selection.size }
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("strong", null, "%{count}", -1),
                _createTextVNode(" selected user ")
              ])),
              _: 2
            }, 1032, ["translate-n", "translate-params"])
          ]),
          (count < _ctx.store.users.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_bp_icon, { icon: "filter" }),
                _createVNode(_component_translate, {
                  "translate-plural": "<strong>%{count}</strong> filtered users",
                  "translate-n": count,
                  "translate-params": { count: count }
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("strong", null, "%{count}", -1),
                    _createTextVNode(" filtered user ")
                  ])),
                  _: 2
                }, 1032, ["translate-n", "translate-params"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_bp_icon, { icon: "user" }),
            _createVNode(_component_translate, {
              "translate-plural": "<strong>%{count}</strong> users",
              "translate-n": _ctx.store.users.length,
              "translate-params": { count: _ctx.store.users.length }
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("strong", null, "%{count}", -1),
                _createTextVNode(" user ")
              ])),
              _: 1
            }, 8, ["translate-n", "translate-params"])
          ])
        ]),
        toolbar: _withCtx(({ selection }) => [
          _createVNode(_component_bp_button, {
            icon: "arrows-rotate",
            color: "yellow",
            onClick: _ctx.store.reload
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Refresh")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_bp_button, {
            icon: "plus",
            color: "green",
            to: { name: 'admin.user.new' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("New")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_bp_button, {
            icon: ['far', 'pencil'],
            color: "light-blue",
            disabled: selection.size !== 1,
            onClick: ($event: any) => (_ctx.updateUser(Array.from(selection)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Edit")
                ])),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]),
          _createVNode(_component_bp_input, {
            class: "ml-auto",
            type: "toggle",
            text: _ctx.$gettext('Only Cordaware staff'),
            modelValue: _ctx.filterAdmins,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterAdmins) = $event))
          }, null, 8, ["text", "modelValue"]),
          _createVNode(_component_bp_button, {
            icon: ['far', 'trash-can'],
            color: "red",
            disabled: selection.size === 0,
            onClick: ($event: any) => (_ctx.showConfirmation('delete', Array.from(selection)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Delete")
                ])),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        "cell-timestamp": _withCtx(({ item }) => [
          _createTextVNode(_toDisplayString(_ctx.localizeDatetime(item.password.timestamp)), 1)
        ]),
        actions: _withCtx(({ item }) => [
          (item.authenticator?.active)
            ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                key: 0,
                icon: _ctx.slashIcon('laptop-mobile'),
                onClick: ($event: any) => (_ctx.showConfirmation('2fa', item._id))
              }, null, 8, ["icon", "onClick"])), [
                [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Disable two-factor authentication'))]
              ])
            : _createCommentVNode("", true),
          (!item.activated)
            ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                key: 1,
                icon: "user-unlock",
                onClick: ($event: any) => (_ctx.showConfirmation('activated', item._id))
              }, null, 8, ["onClick"])), [
                [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Authenticate user'))]
              ])
            : _createCommentVNode("", true),
          (item.adminCandidate)
            ? _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                key: 2,
                icon: item.admin ? _ctx.slashIcon('user-tie') : 'user-tie',
                onClick: ($event: any) => (_ctx.showConfirmation('admin', item._id))
              }, null, 8, ["icon", "onClick"])), [
                [_directive_tooltip, _ctx.actionTooltip(item.admin ? _ctx.$gettext('Revoke admin privileges') : _ctx.$gettext('Grant admin privileges'))]
              ])
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(_component_bp_icon, {
            color: "red",
            icon: ['far', 'trash-can'],
            onClick: ($event: any) => (_ctx.showConfirmation('delete', item._id))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Delete user'))]
          ])
        ]),
        _: 1
      }, 8, ["data", "disabled-rows", "headers", "loading"])
    ]),
    _createVNode(_component_bp_window, {
      type: "warning",
      modelValue: _ctx.confirmation.visibility,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmation.visibility) = $event))
    }, {
      header: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.confirmation.title), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_form_button, {
          icon: "check",
          color: "green",
          action: _ctx.confirmation.action
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("Confirm")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["action"]),
        _createVNode(_component_bp_form_button, {
          type: "cancel",
          action: _ctx.hideConfirmation
        }, null, 8, ["action"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.confirmation.text), 1),
        _createElementVNode("table", null, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Company name")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("Firstname")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode("Lastname")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode("Mail")
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmation.users, (user) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: user._id
              }, [
                _createElementVNode("td", null, _toDisplayString(user.companyName), 1),
                _createElementVNode("td", null, _toDisplayString(user.forename), 1),
                _createElementVNode("td", null, _toDisplayString(user.surname), 1),
                _createElementVNode("td", null, _toDisplayString(user.email), 1)
              ]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}