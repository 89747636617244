
import { $gettext, $ngettext } from 'vue-gettext';
import { actionTooltip, Header, toBoolean, toDatetime, toMail, toTel } from '@/components/table/BpTable';
import { byKey } from '@/utils/array';
import { currentLanguage } from '@/translation';
import { defineComponent, ref, watch } from 'vue';
import { localizeDatetime } from '@/utils/date';
import { slashIcon } from '@/components/icon/BpIcon';
import { translatedUnitEnumeration } from '@/utils/string';
import { useLanguageStore } from '@/stores/language';
import { User, useUserStore } from '@/stores/user';
import { useUser } from '@/utils/user';
import { useVersionStore } from '@/stores/version';
import BpToast from '@/components/toast/BpToasts';
import router from '@/router';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'Users',
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useUserStore(true);
    const languageStore = useLanguageStore();
    const versionStore = useVersionStore();

    ///-------------------------------------------------------------------
    /// USER
    ///-------------------------------------------------------------------

    const user = useUser();

    const disabledRows = (item: User) => item._id === user.value._id;

    ///-------------------------------------------------------------------
    /// UPDATE
    ///-------------------------------------------------------------------

    function updateUser(id: string | string[]) {
      if (Array.isArray(id)) {
        id = id[0];
      }
      router.push({
        name: 'admin.user.edit',
        params: {
          id
        }
      });
    }

    ///-------------------------------------------------------------------
    /// HEADERS
    ///-------------------------------------------------------------------

    const headers = ref<Header[]>([]);
    watch(() => [currentLanguage.value, versionStore.isLoading()], () => {
      if (!versionStore.loaded || versionStore.isLoading()) { return }
      headers.value = [
        {
          _id: 'activated',
          name: $gettext('Authenticated'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'check', color: 'green' },
              detect: (_user: User, activated: User['activated']) => activated,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (_user: User, activated: User['activated']) => !activated,
            }
          ],
          notSortable: true,
          width: '0.8fr',
          transform: toBoolean,
        },
        {
          _id: 'companyName',
          name: $gettext('Company name'),
        },
        {
          _id: 'email',
          name: $gettext('Mail'),
          canCopy: true,
          width: '1.5fr',
          transform: toMail,
        },
        {
          _id: 'forename',
          name: $gettext('Firstname'),
        },
        {
          _id: 'surname',
          name: $gettext('Lastname'),
        },
        {
          _id: 'phone',
          name: $gettext('Phone'),
          canCopy: true,
          transform: toTel,
        },
        {
          _id: 'password.timestamp',
          name: $gettext('Registered'),
          transform: toDatetime,
        },
        {
          _id: 'authenticator.active',
          name: $gettext('2FA'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'check', color: 'green' },
              detect: (user: User) => !!user.authenticator?.active,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (user: User) => !user.authenticator?.active,
            }
          ],
          notSortable: true,
          width: '0.8fr',
          transform: (user: User) => !!user.authenticator?.active,
        },
        {
          _id: 'email_notifications',
          name: $gettext('Email notification'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'check', color: 'green' },
              detect: (_user: User, email_notifications: User['email_notifications']) => Object.values(email_notifications).map(emailNotification => emailNotification.active).includes(true),
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (_user: User, email_notifications: User['email_notifications']) => !Object.values(email_notifications).map(emailNotification => emailNotification.active).includes(true),
            }
          ],
          tooltip: (_user: User, email_notifications: User['email_notifications']) => {
            return Object.values(email_notifications).map(emailNotification => emailNotification.active).includes(true)
              ? Object.entries(email_notifications)
              .map(([type, emailNotification]) => `
                <p class="flex gap-sm">
                  <strong>${type === 'download' ? $gettext('New software versions') : $gettext('New video uploads')}</strong>
                  ${emailNotification.active ? `<em>${languageStore.getNameOf(emailNotification.language)}</em>` : ''}
                </p>
                <ul style="column-count: 2; column-gap: var(--padding); list-style-type: none; padding: 0;">
                  ${versionStore.getRootProducts().sort(byKey('uuid')).map(product => emailNotification.active && emailNotification.products.includes(product.uuid)
                    ? `<li class="flex gap-sm"><span style="color: var(--color-green-600); flex-shrink: 0; text-align: center; width: 1.25em;">&checkmark;</span><span>${versionStore.getName(product)}</span></li>`
                    : `<li class="flex gap-sm"><span style="color: var(--color-red-600); flex-shrink: 0; text-align: center; width: 1.25em;">&cross;</span><span style="color: var(--theme-text-disabled); text-decoration: line-through;">${versionStore.getName(product)}</span></li>`
                  ).join('')}
                </ul>
              `)
              .join('')
            : undefined;
          },
          notSortable: true,
          width: '0.8fr',
          transform: (_user: User, email_notifications: User['email_notifications']) => {
            return Object.values(email_notifications).map(emailNotification => emailNotification.active).includes(true);
          },
        },
        {
          _id: 'admin',
          name: $gettext('Admin'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'check', color: 'green' },
              detect: (_user: User, admin: User['admin']) => admin,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (_user: User, admin: User['admin']) => !admin,
            }
          ],
          notSortable: true,
          width: '0.8fr',
          transform: toBoolean,
        },
      ];
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// CONFIRMATION DIALOG
    ///-------------------------------------------------------------------

    const defaultConfirmation = {
      visibility: false,
      title: '',
      text: '',
      users: [],
      action: () => undefined,
    }

    const confirmation = ref<{
      action: (user?: User | User[]) => void,
      text: string,
      title: string,
      users: User[],
      visibility: boolean,
    }>(clone(defaultConfirmation))

    function showConfirmation(type: '2fa' | 'activated' | 'admin' | 'delete', ids: string | string[]) {
      // USERS
      if (!Array.isArray(ids)) {
        ids = [ids];
      }
      const users = store.getById(ids);
      confirmation.value.users = Array.isArray(users) ? users : [users];

      // TITLE, TEXT & ACTION
      switch (type) {
        case '2fa': {
          confirmation.value.title = $gettext('Disable two-factor authentication confirmation');
          confirmation.value.text = $gettext('Do you really want to disable the two-factor authentication for the following user?');
          confirmation.value.action = () => disable2FA(confirmation.value.users[0]);
          break;
        }
        case 'activated': {
          confirmation.value.title = $gettext('Authenticate user confirmation');
          confirmation.value.text = $gettext('Do you really want to authenticate the following user?');
          confirmation.value.action = () => enableActivated(confirmation.value.users[0]);
          break;
        }
        case 'admin': {
          const user = confirmation.value.users[0];
          confirmation.value.title = user?.admin
            ? $gettext('Revoke admin privileges confirmation')
            : $gettext('Grant admin privileges confirmation');
          confirmation.value.text = user?.admin
            ? $gettext('Do you really want to revoke the admin privileges of the following user?')
            : $gettext('Do you really want to grant the admin privileges for the following user?');
          confirmation.value.action = () => toggleAdmin(user);
          break;
        }
        case 'delete': {
          confirmation.value.title = $ngettext('Delete user confirmation', 'Delete users confirmation', confirmation.value.users.length);
          confirmation.value.text = $ngettext('Do you really want to delete the following user?', 'Do you really want to delete the following users?', confirmation.value.users.length);
          confirmation.value.action = () => deleteUsers(confirmation.value.users);
          break;
        }
      }

      // VISIBILITY
      confirmation.value.visibility = true;
    }

    function hideConfirmation() {
      confirmation.value = clone(defaultConfirmation)
    }

    ///-------------------------------------------------------------------
    /// DISABLE 2FA
    ///-------------------------------------------------------------------

    async function disable2FA(user?: User) {
      if (!user) return;
      const response = await store.disable2FA(user._id);
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: $gettext('Two-factor authentication successfully deactivated'),
          content: $gettext('The two-factor authentication for this user was successfully deactivated.'),
          icon: 'check',
        });
        confirmation.value.visibility = false;
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Two-factor authentication deactivation failed'),
          content: $gettext('The two-factor authentication for this user could not be deactivated.'),
          icon: 'xmark',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// TOGGLE ADMIN
    ///-------------------------------------------------------------------

    async function toggleAdmin(user?: User) {
      if (!user) return;
      const response = await store.toggleAdmin(user._id);
      if (response?.success) {
        const updatedUser = response.data;
        if (updatedUser) {
          BpToast.show({
            color: 'green',
            title: updatedUser.admin
              ? $gettext('Admin privileges successfully granted')
              : $gettext('Admin privileges successfully revoked'),
            content: updatedUser.admin
              ? $gettext('The admin privileges for this user were successfully granted.')
              : $gettext('The admin privileges of this user were successfully revoked.'),
            icon: 'check',
          });
        }
        confirmation.value.visibility = false;
      } else {
        BpToast.show({
          color: 'red',
          title: user.admin
            ? $gettext('Failed to revoke admin privileges')
            : $gettext('Failed to grant admin privileges'),
          content: user.admin
            ? $gettext('Failed to revoke the admin privileges of this user.')
            : $gettext('Failed to grant the admin privileges for this user.'),
          icon: 'xmark',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// ENABLE AUTHENTICATION
    ///-------------------------------------------------------------------

    async function enableActivated(user?: User) {
      if (!user) return;
      const response = await store.enableActivated(user._id);
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: $gettext('User authentication success'),
          content: $gettext('The user was successfully authenticated.'),
          icon: 'check',
        });
        confirmation.value.visibility = false;
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('User authentication failed'),
          content: $gettext('The user authentication has failed.'),
          icon: 'xmark',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// DELETE USERS
    ///-------------------------------------------------------------------

    async function deleteUsers(users: User[]) {
      if (users.length === 0) return;
      const response = await store.delete(users.map(user => user._id));
      if (response?.success) {
        const deletedUsers = response.data;
        if (deletedUsers) {
          if (deletedUsers.length === users.length) {
            BpToast.show({
              color: 'green',
              title: $gettext('%{list} successfully deleted', { list: translatedUnitEnumeration({
                deletedUsers: [users.length, deletedUsers.length],
              }, { includeCount: false }) }),
              content: $ngettext(
                '<strong>%{list}</strong> was successfully deleted.',
                '<strong>%{list}</strong> were successfully deleted.',
                deletedUsers.length,
                {
                  list: translatedUnitEnumeration({
                    deletedUsers: [users.length, deletedUsers.length],
                  })
                }),
              icon: 'check',
            });
          } else {
            BpToast.show({
              color: 'red',
              title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
                deletedUsers: [users.length, deletedUsers.length],
              }, { difference: true }) }),
              content: [
                $ngettext(
                  '<strong>%{list}</strong> was successfully deleted.',
                  '<strong>%{list}</strong> were successfully deleted.',
                  deletedUsers.length,
                  {
                    list: translatedUnitEnumeration({
                      deletedUsers: [users.length, deletedUsers.length],
                    })
                  }),
                $gettext(
                  'Failed to delete <strong>%{list}</strong>.',
                  {
                    list: translatedUnitEnumeration({
                      deletedUsers: [users.length, deletedUsers.length],
                    }, { difference: true })
                  }),
              ].join('<br>'),
              icon: 'xmark',
            });
          }
        } else {
          BpToast.show({
            color: 'rose',
            title: 'You should not see this!',
            content: 'Something went terribly wrong!',
            icon: 'triangle-exclamation',
          });
        }
        confirmation.value.visibility = false;
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
            users: users.length,
          }, { includeCount: false }) }),
          content: $gettext('Failed to delete <strong>%{list}</strong>.', { list: translatedUnitEnumeration({
            users: users.length,
          }) }),
          icon: 'xmark',
        });
      }
    }
  
    ///-------------------------------------------------------------------
    /// ADMINS
    ///-------------------------------------------------------------------

    const filterAdmins = ref(false);

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      actionTooltip,
      confirmation,
      disabledRows,
      filterAdmins,
      headers,
      hideConfirmation,
      localizeDatetime,
      showConfirmation,
      slashIcon,
      store,
      updateUser,
    }
  }
});
